<script lang="ts" setup>
import Title2 from '@/components/Title/Title2.vue'
import { Autoplay, Scrollbar } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore from 'swiper'
import 'swiper/css'
import 'swiper/css/scrollbar'
import type { Card3List } from '@/utils/types'
import Card3 from '@/components/Card/Card3.vue'

const props = defineProps({
  data: {
    type: Array as PropType<Card3List[]>,
    default: []
  }
})
SwiperCore.use([Autoplay, Scrollbar])

const currentIndex: Ref<number> = ref(0)

const swiperSlideSelector = ref()
const swiperSlideSelectorMobile = ref()
const onSwiper = (data: any) => {
  //存放 swiper
  swiperSlideSelector.value = data
}

const swiperTo = (index: number) => {
  swiperSlideSelector.value.slideTo(index)
  swiperSlideSelectorMobile.value.slideTo(index)
}

const handelSwiperChange = (data: any) => {
  currentIndex.value = data.realIndex
}

const handleSlidePrev = () => {
  swiperSlideSelector.value.slidePrev()
  swiperSlideSelectorMobile.value.slidePrev()
}
const handleSlideNext = () => {
  swiperSlideSelector.value.slideNext()
  swiperSlideSelectorMobile.value.slideNext()
}
</script>

<template>
  <section class="story">
    <div class="wrap">
      <Title2 class="story__title" title="STORY" />
      <swiper
        :autoplay="{
          delay: 3000
        }"
        :slides-per-view="2"
        :speed="300"
        :loop="true"
        :scrollbar="{ draggable: true }"
        :breakpoints="{
          321: {
            spaceBetween: 15
          },
          481: {
            spaceBetween: 102
          }
        }"
        class="swiper-list"
        @swiper="onSwiper"
        @slide-change="handelSwiperChange"
      >
        <swiper-slide v-for="(item, index) in data" :key="index">
          <div class="swiper-list__box">
            <Card3 :data="item" />
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.story {
  margin-bottom: 200px;

  @include max-media(767) {
    margin-bottom: 70px;
  }

  .wrap {
    max-width: 950px;
  }

  &__title {
    margin-bottom: 120px;
    text-align: center;

    @include max-media(767) {
      margin-bottom: 30px;
    }
  }

  .swiper-list {
    padding-bottom: 80px;

    @include max-media(767) {
      padding-bottom: 30px;
    }
  }
}
</style>
