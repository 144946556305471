<script lang="ts" setup>
const props = defineProps({
  data: {
    type: Object as () => Record<string, any>, // 指定 data 的類型為非空的物件
    required: true, // 告訴 TypeScript data 是必需的
  },
});
</script>

<template>
  <nuxt-link :to="props.data.link" class="card">
    <figure class="card__pic imh__opacity">
      <NuxtImg :src="props.data.image"></NuxtImg>
    </figure>
    <div class="card__item texth_line">
      <div class="card__titleEn" v-html="props.data.titleEn || ''"></div>
      <div class="card__titleTw" v-html="props.data.titleTw || ''"></div>
    </div>
  </nuxt-link>
</template>

<style lang="scss" scoped>
.card {
  &__pic {
    margin-bottom: 50px;

    @include max-media(640) {
      margin-bottom: 20px;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &__item {
    &:hover {
      opacity: 0.7;
    }
  }

  &__titleEn {
    display: block;
    margin-bottom: 12px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.13;
    color: rgba(#000, 0.8);

    @include max-media(480) {
      font-size: 7px;
      margin-bottom: 8px;
    }
  }

  &__titleTw {
    display: block;
    text-align: center;
    font-weight: 400;
    font-size: 16px;

    @include max-media(480) {
      font-size: 9px;
    }
  }
}
</style>
